import Mn from 'backbone.marionette';
import Bk from 'backbone';
import $ from 'jquery';
import _ from 'underscore';
import scope from '../scope';
import BaseView from './base';
import Component from './component';
import ComponentList from './component-list';

var View = Mn.View.extend(BaseView({
    name: 'no-name-view',
    protoslug: 'page',
    protoclass: Mn.View.prototype,
    getDefaultComponent: function(){
        return Component;
    },
    getDefaultComponentList: function(){
        return ComponentList;
    },
}));

export default View;
