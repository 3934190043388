import Mn from 'backbone.marionette';
import Bk from 'backbone';
import $ from 'jquery';
import scope from '../scope';
import BaseView from './base';
import ComponentList from './component-list';

var Component = Mn.View.extend(BaseView({
    name: 'no-name-component',
    protoslug: 'component',
    protoclass: Mn.View.prototype,
    render: function () {
        var result = Mn.View.prototype.render.apply(this, arguments);

        for (var key in this._uiBindings) {
            this.ui[key].selector = this._uiBindings[key];
        }

        return result;
    },
    getDefaultComponent: function(){
        return Component;
    },
    getDefaultComponentList: function(){
        return ComponentList;
    },
}));

export default Component;

