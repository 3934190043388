import Mn from 'backbone.marionette';
import Bk from 'backbone';
import $ from 'jquery';
import scope from '../scope';
import BaseView from './base';
import Component from './component';
import _ from 'underscore';

Mn.CollectionView.prototype = _.defaults(Mn.CollectionView.prototype, Mn.View.prototype);

var ClassOptions = ['behaviors', 'childView', 'childViewContainer', 'childViewEventPrefix', 'childViewEvents', 'childViewOptions', 'childViewTriggers', 'collectionEvents', 'emptyView', 'emptyViewOptions', 'events', 'modelEvents', 'sortWithCollection', 'template', 'templateContext', 'triggers', 'ui', 'viewComparator', 'viewFilter', 'regionClass', 'regions'];
var Base = BaseView({});

var ComponentList = Mn.CollectionView.extend(BaseView({
    name: 'no-name-component-list',
    protoslug: 'component',
    protoclass: Mn.CollectionView.prototype,
    nocreator: true,
    constructor: function constructor(options) {
        Base.constructor.apply(this, arguments);

        this._setOptions(options, ClassOptions);
  
        Mn.monitorViewEvents(this);
  
        this._initChildViewStorage();
  
        this._initBehaviors();

        this._initRegions();
  
        Bk.View.prototype.constructor.apply(this, arguments);
  
        this.getEmptyRegion();
        this.delegateEntityEvents();
  
        this._triggerEventOnBehaviors('initialize', this, options);
    },
    getDefaultComponent: function(){
        return Component;
    },
    getDefaultComponentList: function(){
        return ComponentList;
    },
    onAddChild: function(container, child){
        child.triggerMethod('attach');
        child._parentView = this;
    },
    addComponentContent: function(content){
        this.collection.add(new ($(content).attr('data-component-model') || $(content).parents('[data-component-list]').attr('data-component-model') || Bk.Model)({
            id: $(content).attr('data-component-uid') || $(content).attr('id'),
            el: content
        }));
    }
}));

export default ComponentList;

