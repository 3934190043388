import Bk from 'backbone';
import Mn from 'backbone.marionette';
import $ from 'jquery';
import scope from '../scope';
import _ from 'underscore';

var BaseView = function(options){
    return _.extend({
        constructor: function (args) {
            this.serviceData = {};
            this.callServiceList = [];
            
            if(args.el){
                this.el = args.el;
                this.html = args.html = $(this.el)[0].outerHTML;
            }

            if(args.childViewContainer){
                this.childViewContainer = args.childViewContainer;
            }

            if(!this.service && this.el){
                this.template = false;
            }

            this.setupComponents();

            this.regions = this.regions || {};

            for (var key in this.components) {
                this.regions[key] = {el: this.components[key].region};
            }

            if(this.nocreator){
                return;
            }

            return this.protoclass.constructor.apply(this, arguments);
        },
        setElement: function(){
            this.protoclass.setElement.apply(this, arguments);

            $(this.$el).data('instance', this);

            return this;
        },
        _addRegion: function(region){
            this.protoclass._addRegion.apply(this, arguments);
            
            region.html = $(region.$el)[0].outerHTML;

            $(region.$el).data('instance', region);
            
            return this;
        },
        attachElContent: function(html){
            this.html = html;
            this.protoclass.attachElContent.apply(this, arguments);
        },
        load: function () {
            var self = this;
            var defr = $.Deferred();

            defr.name = self.name;
            defr.type = self.protoslug + '-load';

            if (self.service) {
                self.callServiceBatch(self.service);
            }

            if (self.components) {
                $.each(self.components, function (key, component) {
                    var defrComponent = $.Deferred();

                    defrComponent.name = self.name;
                    defrComponent.subname = key;
                    defrComponent.type = self.protoslug + '-child';

                    self.createComponent(component);

                    if (component.instance) {
                        self.callServiceList.push(defrComponent);

                        component.instance
                            .load()
                            .done(function (view) {
                                setTimeout(function () {
                                    defrComponent.resolve();
                                }, 100);
                            })
                            .catch(function () {
                                defrComponent.reject();
                            });
                    }
                });
            }

            $.when
                .apply($, self.callServiceList)
                .done(function () {
                    defr.resolve(self);
                })
                .catch(function () {
                    defr.reject();
                });

            return defr;
        },
        populate: function () {
            var self = this;

            $.each(self.components, function (key, component) {
                var view = component.instance;

                view.populate();
                self.getRegion(key).show(view);
                view.triggerMethod('attach', view);
            });

            if(self.children) {
                $.each(self.children._views, function (key, child) {
                    child.populate();
                });
            }

            self.bindUIElements();
        },
        reload: function () {
            var self = this;

            return self.load().done(function () {
                self.render();
            });
        },
        callServiceBatch: function (services) {
            var self = this;

            return $.each(services, function (key, values) {
                self.callServiceList.push(self.callServiceAdd($.extend({ name: key }, values)));
            });
        },
        callServiceAdd: function (service) {
            var self = this;
            var slug = service.name || self.name;
            var defrService = $.Deferred();

            defrService.name = slug;
            defrService.type = self.protoslug + '-service';

            $.ajax(service)
                .done(function (data) {
                    data = data || {};

                    try {
                        if (self.getLayout().getComponents('content')[0].instance.checkauth) {
                            if (data.errors && data.errors[0].code === '-1') {
                                defrService.reject(data.errors[0]);

                                return;
                            }
                        }
                    } catch (e) {}

                    if (!scope.regionsData[self.name]) {
                        scope.regionsData[self.name] = {};
                    }

                    data = service.convert ? service.convert(data) : data.data ? data.data : data;
                    self.serviceData[slug] = scope.regionsData[self.name][slug] = service.scope ? data[service.scope] : data;

                    if (service.scope) {
                        delete data[service.scope];
                        self.serviceData[slug] = $.extend(self.serviceData[slug], data);
                    }

                    self.trigger(slug + '.onComplete', true, data);

                    defrService.resolve(data);
                })
                .catch(function (e) {
                    console.error(e);
                    self.serviceData[slug] = null;
                    self.trigger(slug + '.onComplete', false, e);
                    defrService.reject();
                });

            return defrService;
        },
        serializeTemplate: function (data, context, insert) {
            if(this.protoslug === 'page'){
                return this.protoclass.serializeTemplate.apply(this, arguments);
            }

            var tpl = $(this.template(data));

            console.log(context, insert);

            if (insert && insert == 'append') {
                context.append(tpl.find(context.selector).html());
            }
            if (insert == 'prepend') {
                context.prepend(tpl.find(context.selector).html());
            } else {
                context.html(tpl.find(context.selector).html());
            }
        },
        onAttach: function () {
            return undefined;
        },
        getLayout: function () {
            if (this._parent) {
                return this._parent.getLayout();
            }

            return this;
        },
        setupComponents: function(){
            var self = this;
            var tmp = this.$el || $(this.el);

            if(!self.components) {
                self.components = {};
            }

            var childComponents = tmp.find('[data-component]');
            var childPage = tmp.find('[data-view]');
            var childContainer = tmp.find(self.childViewContainer);

            console.log('base:setupcomponents', childComponents);

            childComponents.filter(function(){
                return $(this).closest(childPage).length === 0 && $(this).closest(childContainer).length === 0 && $(this).closest(childComponents.not(this)).length === 0;
            }).each(function(){
                self.setupComponent(this);
            })
        },
        setupComponent: function(component, force){
            var self = this;
            var name = $(component).attr('data-component') || '';
            var list = $(component).attr('data-component-list');
            var collection = $(component).attr('data-component-collection');
            var model = $(component).attr('data-component-model');

            var id = $(component).attr('id');
            var uid = $(component).attr('data-component-uid') || id;

            console.log('base:setupcomponent', component, name, list, uid);

            if(uid){
                name = name + ':' + uid;
            }

            if(!self.components[name] || force) {
                var children = $(component).children();

                if(children.length > 1){
                    return console.error('Componente deve conter um wrapper declarado.')
                }

                if(!list){
                    self.components[name] = {
                        region: id || component,
                        component: scope.components[name.split(':')[0]] || self.getDefaultComponent(),
                        options: {
                            id: uid,
                            el: children
                        }
                    }
                }else{
                    var collection = new (scope.collection[collection] || Bk.Collection)();

                    $(component).find(list).children().each(function(){
                        collection.add(new ($(this).attr('data-component-model') || model || Bk.Model)({
                            id: $(this).attr('data-component-uid') || $(this).attr('id'),
                            el: this
                        }));
                    })

                    self.components[name] = {
                        region: id || component,
                        component: scope.components[name.split(':')[0]] || self.getDefaultComponentList(),
                        options: {
                            id: uid,
                            el: children,
                            collection: collection,
                            childView: function(item){
                                var component = $(item.attributes.el).attr('data-component');

                                return scope.components[component] || self.getDefaultComponent();
                            },
                            childViewOptions: function(item){
                                return {
                                    id: item.attributes.id,
                                    el: item.attributes.el,
                                    template: function(attributes){
                                        return attributes.el.html();
                                    }
                                }
                            },
                            childViewContainer: list
                        }
                    }
                }

                if(force) {
                    return this.createComponent(self.components[name]);
                }
            }
        },
        createComponent: function(component){
            if (component.component) {
                component.instance = new component.component(component.options);
            }

            if (component.instance) {
                component.instance._parent = self;
            }

            return component;
        },
        getComponents: function (name) {
            var self = this;
            var result = [];

            $.each(self.components, function (key, component) {
                if (key.split(':')[0] == name.split(':')[0]) {
                    result.push(component);
                } else {
                    result = result.concat(component.instance.getComponents(name));
                }
            });

            return result;
        },
        getComponent: function(name){
            return this.getRegion(name);
        },
        changeComponentContent: function(name, content, preserve){
            var self = this;
            var component = this.setupComponent(content, !preserve);

            if(component) {
                return component.instance.load().done(function (view) {
                    console.log('base:changeComponentContent->ref', component.options.id ? name + ':' + component.options.id : name);
                    var region = self.getComponent(component.options.id ? name + ':' + component.options.id : name);

                    console.log('base:changeComponentContent->result', region);
                    region.show(view);

                    region.html = $(region.$el)[0].outerHTML;

                    
                    view.populate();

                    setTimeout(function(){
                        self.bindUIElements();
                        self.triggerMethod('componentChange', view);
                    }, 1)
                })
            }

        },
        bindTrigger: function(name){
            var self = this;
            
            return function(){
                self.triggerMethod.apply(self, [name, ...arguments]);
            }
        }
    }, options);
};

export default BaseView;

